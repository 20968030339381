export function useLogout() {
  const { $auth } = useNuxtApp()
  const router = useRouter()
  const toast = useToast()
  const $analytics = useAnalytics()

  const logout = async () => {
    if ($auth.loggedIn) {
      $analytics.trackElementClicked({ elementType: 'link', text: 'log out' })
      await $auth.logout()
      await router.push('/login')

      setTimeout(() => {
        toast.add({
          severity: 'info',
          summary: 'You have been logged out.',
          detail: 'Sign back in to continue.',
          life: 8000,
        })
      }, 100)
    }
  }

  return { logout }
}
